import React, { useState, useEffect, type FC, type SVGProps } from 'react';

import { ReactComponent as alignCenter } from './svgs/align-center.svg';
import { ReactComponent as alignLeft } from './svgs/align-left.svg';
import { ReactComponent as arrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as arrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as arrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as chevronDown } from './svgs/chevron-down.svg';
import { ReactComponent as circle } from './svgs/circle.svg';
import { ReactComponent as lookingGlass } from './svgs/looking-glass.svg';
import { ReactComponent as minus } from './svgs/minus.svg';
import { ReactComponent as playCircle } from './svgs/play-circle.svg';
import { ReactComponent as plus } from './svgs/plus.svg';
import { ReactComponent as warningTriangle } from './svgs/warning-triangle.svg';
import { ReactComponent as x } from './svgs/x.svg';
import { ReactComponent as undo } from './svgs/undo.svg';
import { ReactComponent as download } from './svgs/download.svg';
import { ReactComponent as copy } from './svgs/copy.svg';
import { ReactComponent as lock } from './svgs/lock.svg';
import { ReactComponent as cross } from './svgs/cross.svg';
import { ReactComponent as bin } from './svgs/bin.svg';
import { ReactComponent as refresh } from './svgs/refresh.svg';
import { ReactComponent as reset } from './svgs/reset.svg';
import { ReactComponent as pen } from './svgs/pen.svg';
import { ReactComponent as file } from './svgs/file.svg';
import { ReactComponent as logomark } from './svgs/logomark.svg';
import { ReactComponent as wordmark } from './svgs/wordmark.svg';
import { ReactComponent as edit } from './svgs/edit.svg';
import { ReactComponent as externalLink } from './svgs/external-link.svg';
import { ReactComponent as atfL } from './svgs/atf-l.svg';
import { ReactComponent as atfLRotated } from './svgs/atf-l-rotated.svg';
import { ReactComponent as atfM } from './svgs/atf-m.svg';
import { ReactComponent as atfMRotated } from './svgs/atf-m-rotated.svg';
import { ReactComponent as check } from './svgs/check.svg';
import { ReactComponent as madeWithLAM } from './svgs/made-with-lam.svg';
import { ReactComponent as year2024 } from './svgs/2024.svg';
import { ReactComponent as year2025 } from './svgs/2025.svg';
import { ReactComponent as year2026 } from './svgs/2026.svg';
import { ReactComponent as year2027 } from './svgs/2027.svg';
import { ReactComponent as year2028 } from './svgs/2028.svg';
import { ReactComponent as year2029 } from './svgs/2029.svg';
import { ReactComponent as year2030 } from './svgs/2030.svg';
import { ReactComponent as year2031 } from './svgs/2031.svg';
import { ReactComponent as year2032 } from './svgs/2032.svg';
import { ReactComponent as year2033 } from './svgs/2033.svg';
import { ReactComponent as year2034 } from './svgs/2034.svg';

export const svgs = Object.freeze({
  alignCenter,
  alignLeft,
  arrowDown,
  arrowLeft,
  arrowRight,
  chevronDown,
  circle,
  lookingGlass,
  download,
  minus,
  check,
  pen,
  file,
  logomark,
  playCircle,
  plus,
  copy,
  cross,
  bin,
  warningTriangle,
  refresh,
  reset,
  x,
  wordmark,
  undo,
  atfL,
  edit,
  externalLink,
  atfLRotated,
  atfM,
  atfMRotated,
  lock,
  logo: wordmark,
  madeWithLAM,
  year2024,
  year2025,
  year2026,
  year2027,
  year2028,
  year2029,
  year2030,
  year2031,
  year2032,
  year2033,
  year2034
});

export type TSVG = keyof typeof svgs;

export interface ISVG {
  svg: TSVG;
  className?: string;
  style?: React.CSSProperties;
}

const SVG = ({ svg, ...props }: ISVG) => {
  const [LogoComponent, setLogoComponent] = useState<
    FC<SVGProps<SVGSVGElement>>
  >(() => wordmark);

  const loadCustomLogo = async () => {
    const customAssets = (await import(
      '@lam-agency/toolkit/custom/components'
    )) as any; // to suppress error if custom components file does not exist

    const CustomLogo = customAssets.Logo;

    setLogoComponent(() => CustomLogo || wordmark);
  };

  useEffect(() => {
    loadCustomLogo();
  }, []);

  if (!svgs[svg]) return null;

  if (svg === 'logo') {
    return <LogoComponent {...props} aria-hidden />;
  }

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
