import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  NoJs,
  Theme,
  Header,
  Footer,
  Notification,
  ScrollToTop,
  LoadingScreen,
  StyleTokens,
  Password
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import 'modern-normalize/modern-normalize.css';
import '@lam-agency/toolkit/styles/global.css';
import { useSyncMVT } from '../hooks';
import { useMVT } from '../stores';
import { localStorage } from '../utils';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => {
  useSyncMVT();
  const [haveFontsLoaded, setHaveFontsLoaded] = useState(false);
  const [isPasswordActive, setIsPasswordActive] = useState(false);
  const { hasMVTDataLoaded, projectString } = useMVT();

  const PASSWORD_KEY = `${projectString}_last_password_time`;
  const SESSION_EXPIRY_THRESHOLD_MINUTES = 1;

  const updateLastSessionLocalStorage = () => {
    const currentTimeMilliseconds = new Date().getTime();
    localStorage.setItem(PASSWORD_KEY, currentTimeMilliseconds);
  };

  const onSubmitCorrectPassword = () => {
    setIsPasswordActive(false);
    updateLastSessionLocalStorage();
  };

  const hasLoaded = haveFontsLoaded && hasMVTDataLoaded;

  // Password logic
  useEffect(() => {
    if (!hasLoaded) return;

    const shouldActivatePassword = () => {
      const password = process.env.GATSBY_SITE_PASSWORD;

      console.log('Password:', password);
      if (!password) {
        return false;
      }

      console.log('Password:', password);

      const currentTimeMilliseconds = new Date().getTime();
      const timeCorrectPasswordLastEnteredMilliseconds =
        localStorage.getItem(PASSWORD_KEY);

      if (!timeCorrectPasswordLastEnteredMilliseconds) {
        return true;
      }

      const differenceMilliseconds =
        currentTimeMilliseconds - timeCorrectPasswordLastEnteredMilliseconds;
      const millisecondsInMinute = 1000 * 60;
      const differenceMinutes = differenceMilliseconds / millisecondsInMinute;

      const isSessionExpired =
        differenceMinutes > SESSION_EXPIRY_THRESHOLD_MINUTES;

      return isSessionExpired;
    };

    if (shouldActivatePassword()) {
      setIsPasswordActive(true);
    }
  }, [PASSWORD_KEY, hasLoaded]);

  // Loading logic
  useEffect(() => {
    document.fonts.ready
      .then(() => {
        setHaveFontsLoaded(true);
      })
      .catch((error) => {
        console.error('An error occurred while loading fonts:', error);
      });
  }, []);

  return (
    <>
      <NoJs />
      <Theme />
      <StyleTokens />

      {hasLoaded && (
        <>
          {isPasswordActive ? (
            <Password onSubmitCorrectPassword={onSubmitCorrectPassword} />
          ) : (
            <MainPageLayout className={className}>{children}</MainPageLayout>
          )}
        </>
      )}

      {!hasLoaded && <LoadingScreen />}
    </>
  );
};

export default Layout;

const MainPageLayout = ({ children, className }: IProps) => {
  return (
    <>
      <Header />

      <div className={cn(styles.container, className)}>{children}</div>

      <Footer />
      <ScrollToTop />
      <Notification />
    </>
  );
};
